import { TextFieldData } from './TextFieldData';
import { RadioNamesAndKeys } from './RadioGroupNamesAndKeys';

export const AdGroupSettingsRadioGroupData = [
    {
        radioTitleSection: {
            title: 'Ad Group Budget',
            subTitle: null,
            name: RadioNamesAndKeys.budget.name,
            key: RadioNamesAndKeys.budget.agKey,
        },
        radioGroup: [
            {
                label: 'Capped',
                subLabel: 'Your campaign will stop running once the budget is reached.',
                textField: true,
                textFieldKey: TextFieldData.budget.agTextFieldKey,
                namesAndValues: {
                    textField: {
                        name: TextFieldData.budget.textFieldName,
                        value: '',
                    },
                    radio: {
                        name: RadioNamesAndKeys.budget.radioGroupName,
                        value: 'capped',
                    },
                },
                key: RadioNamesAndKeys.budget.agRadioKey,
            },
            {
                label: 'Uncapped',
                subLabel: 'Your campaign will spend freely from the funds available to it.',
                textField: false,
                textFieldKey: null,
                value: 'uncapped',
                name: RadioNamesAndKeys.budget.radioGroupName,
                key: RadioNamesAndKeys.budget.agRadioKey,
            },
        ],
    },
    {
        radioTitleSection: {
            title: 'Monthly Budget',
            subTitle: 'Limit how much your campaign can spend per month.',
            name: RadioNamesAndKeys.monthly.name,
            key: RadioNamesAndKeys.monthly.agKey,
        },
        radioGroup: [
            {
                label: false,
                subLabel: false,
                textField: true,
                textFieldKey: TextFieldData.monthly.agBudgetTextFieldKey,
                namesAndValues: {
                    textField: {
                        name: TextFieldData.monthly.textFieldName,
                        value: '',
                    },
                    radio: {
                        name: RadioNamesAndKeys.monthly.radioGroupName,
                        value: RadioNamesAndKeys.textField.radioValue,
                    },
                },
                key: RadioNamesAndKeys.monthly.agKey,
            },
            {
                label: 'None',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'none',
                name: RadioNamesAndKeys.monthly.radioGroupName,
                key: RadioNamesAndKeys.monthly.agKey,
            },
        ],
    },
    {
        radioTitleSection: {
            title: 'Daily Budget',
            subTitle: 'Limit how much your campaign can spend per day.',
            name: RadioNamesAndKeys.daily.name,
            key: RadioNamesAndKeys.daily.agKey,
        },
        radioGroup: [
            {
                label: 'Automated (Recommended)',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'automated',
                name: RadioNamesAndKeys.daily.radioGroupName,
                key: RadioNamesAndKeys.daily.agRadioKey,
            },
            {
                label: false,
                subLabel: false,
                textField: true,
                textFieldKey: TextFieldData.daily.agTextFieldKey,
                namesAndValues: {
                    textField: {
                        name: TextFieldData.daily.textFieldName,
                        value: '',
                    },
                    radio: {
                        name: RadioNamesAndKeys.daily.radioGroupName,
                        value: RadioNamesAndKeys.textField.radioValue,
                    },
                },
                key: RadioNamesAndKeys.daily.agRadioKey,
            },
            {
                label: 'None',
                subLabel: false,
                textField: false,
                textFieldKey: null,
                value: 'none',
                name: RadioNamesAndKeys.daily.radioGroupName,
                key: RadioNamesAndKeys.daily.agRadioKey,
            },
        ],
    },
];

export const BaseAndMaxBidOptions = [
    {
        radioTitleSection: {
            title: null,
            subTitle: null,
            name: 'baseAndMaxBid',
            key: 'baseAndMaxBidKey',
        },
        radioGroup: [
            {
                label: "Don't Apply",
                subLabel: null,
                textField: false,
                textFieldKey: null,
                value: 'dontApply',
                name: RadioNamesAndKeys.optBidding.radioGroupName,
                key: RadioNamesAndKeys.optBidding.baseAndMaxBidRadioKey,
            },
            {
                label: 'Apply Max Bid',
                subLabel: null,
                textField: false,
                textFieldKey: null,
                value: 'applyMaxBid',
                name: RadioNamesAndKeys.optBidding.radioGroupName,
                key: RadioNamesAndKeys.optBidding.baseAndMaxBidRadioKey,
            },
        ],
    },
];
