import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageNotFound from '../../PageNotFound';

const BillingSandboxPage = () => {
    const showBilling = useSelector((state) => state.featureFlags.showBilling);

    if (!showBilling) {
        return (
            <>
                <PageNotFound />
                <Redirect to="/" />
            </>
        );
    }

    return <></>;
};

export default BillingSandboxPage;
