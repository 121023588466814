import { useCallback, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { campaignDefaults } from '../../containers/CampaignManagement/componentData/CampaignManagementDefaults';

const validationSchema = yup.object().shape({
    password: yup.string().required('Password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Confirm password must match')
        .required('Confirm password is required'),
});

const params = {
    initialForms: {
        campaignSettings: campaignDefaults.campaignSettings,
        adGroup: campaignDefaults.adGroup,
        products: campaignDefaults.products,
        searchProducts: campaignDefaults.searchProducts,
        keywords: campaignDefaults.keywords,
        negativeKeywords: campaignDefaults.negativeKeywords,
        review: campaignDefaults.review,
    },
    onSubmit: () => {},
    validate: (values) => {
        try {
            validationSchema.validateSync(values, { abortEarly: false });
            return {};
        } catch (err) {
            const errors = {};
            err.inner.forEach((error) => {
                errors[error.path] = error.message;
            });
            return errors;
        }
    },
};

export const useSponsoredCreation = () => {
    const { initialForms, onSubmit, validate } = params;

    const formsRef = useRef(initialForms);

    useEffect(() => {
        formsRef.current = initialForms;
    }, [initialForms]);

    if (!(initialForms instanceof Object)) {
        throw new Error('Given data must be an object');
    }

    const load = useCallback((tab) => formsRef.current[tab], []);

    const save = useCallback((tab, data) => {
        formsRef.current = {
            ...formsRef.current,
            [tab]: data,
        };
    }, []);

    const submit = useCallback(
        (tab) => {
            const formData = formsRef.current[tab];
            const validationErrors = validate(formData);

            if (Object.keys(validationErrors).length === 0) {
                onSubmit(formData);
            }
        },
        [validate, onSubmit],
    );

    const get = useCallback((tab) => formsRef.current[tab], []);

    const validateForm = useCallback((tab) => validate(formsRef.current[tab]), [validate]);

    return {
        campaignSettings: {
            load: () => load('campaignSettings'),
            save: (data) => save('campaignSettings', data),
            submit: () => submit('campaignSettings'),
            get: () => get('campaignSettings'),
            validateForm: () => validateForm('campaignSettings'),
        },
        adGroup: {
            load: () => load('adGroup'),
            save: (data) => save('adGroup', data),
            submit: () => submit('adGroup'),
            get: () => get('adGroup'),
            validateForm: () => validateForm('adGroup'),
        },
        products: {
            load: () => load('products'),
            get: () => get('products'),
            save: (data) => save('products', data),
        },
        searchProducts: {
            get: () => get('searchProducts'),
        },
        keywords: {
            get: () => get('keywords'),
        },
        negativeKeywords: {
            get: () => get('negativeKeywords'),
        },
        review: {
            load: () => load('review'),
            save: (data) => save('review', data),
            submit: () => submit('review'),
            get: () => get('review'),
            validateForm: () => validateForm('review'),
        },
    };
};
