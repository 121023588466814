import Fuse from 'fuse.js';

/*
When searching products, many products are named almost identically, often time distinguished only by a minor detail.
This can result in a typical relevance-based search placing products the user does not want higher in results, purely based on
irrelevant details in the name. To deal with this issue, this search clumps similar products together,
and then sorts products that are equally close matches by relevance.

In the search options, ignoreLocation and ignoreFieldNorm set to false remove factors that can cause similar matches to have
different scores.

In addition to disabling these options, the products are grouped in the sort function by rounding each product's
relevance score down to the nearest 0.025 before sorting.

With these grouped scores, the products are then sorted first by relevance.
*/

const SEARCH_OPTIONS = {
    shouldSort: false,
    threshold: 0.3,
    ignoreLocation: true,
    minMatchCharLength: 2,
    ignoreFieldNorm: true,
    includeScore: true,
    includeMatches: true,
    keys: ['bsin', 'description', 'color'],
};

function fuzzySearch({ data, term, fuse }) {
    const result = fuse.search(`${term}`);
    return term ? result : data;
}

const sortFn = (a, b) => Math.floor(a.score * 40) - Math.floor(b.score * 40);

/**
 *
 * @param {Object} data contains the dataset to search over
 *
 * @returns {function} function that performs the search
 *
 * A function to that takes a dataset and returns a function that searches over that dataset,
 * with customized fuzzy search options and sorting.
 *
 */
function createProductSearch(data) {
    const fuse = new Fuse(data, SEARCH_OPTIONS);

    return (term) => {
        if (term === '') {
            return [];
        }

        const results = fuzzySearch({ data, term, fuse });

        return results.sort(sortFn);
    };
}

export default createProductSearch;
