/**
 *
 * RadioSelect
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { kebabCase } from 'lodash';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { ArrowDropDown, KeyboardArrowDown } from '@material-ui/icons';
import { Field } from 'react-final-form';
import { RadioWrapper } from '../../containers/CampaignManagement/shared/RadioWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.standardWhite,
        '& .MuiAutocomplete-inputRoot': {
            padding: '4px',
        },
    },
    downArrow: {
        color: theme.palette.primary.main,
    },
}));

function RadioSelect({
    options,
    value,
    placeholder,
    onChange,
    onBlur,
    onInputChange,
    noOptionsText,
    customDropdownIcon,
    input,
}) {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState(value);

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedValue(newValue?.value || '');
        onChange(newValue?.value || '');
    };

    return (
        <div className={classes.root}>
            <Autocomplete
                id={`radio-component-${kebabCase(placeholder)}`}
                options={options}
                getOptionLabel={(option) => option.name}
                onChange={handleAutocompleteChange}
                onInputChange={onInputChange}
                onBlur={onBlur}
                blurOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...input}
                        variant="outlined"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        inputProps={{
                            ...params.inputProps,
                            placeholder,
                            value: selectedValue,
                        }}
                    />
                )}
                value={options.find((option) => option.value === value) || null}
                defaultChecked
                noOptionsText={noOptionsText}
                renderTags={() => {}}
                renderOption={(option) => (
                    <RadioGroup>
                        <div>
                            <FormControlLabel
                                value={option.value}
                                control={
                                    <>
                                        <Field
                                            /* eslint-disable-next-line react/no-array-index-key */
                                            key={option.radioKey + option.name}
                                            name="optStrategy"
                                            option={option}
                                            onChange={onChange}
                                            type="radio"
                                            value={option.value}
                                            component={RadioWrapper}
                                        />
                                        {option.name}
                                    </>
                                }
                            />
                        </div>
                    </RadioGroup>
                )}
                popupIcon={customDropdownIcon ? <KeyboardArrowDown className={classes.downArrow} /> : <ArrowDropDown />}
            />
        </div>
    );
}

RadioSelect.defaultProps = {
    onChange: () => {},
};

RadioSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onBlur: PropTypes.func,
    noOptionsText: PropTypes.string,
    customDropdownIcon: PropTypes.bool,
    input: PropTypes.object,
};

export default RadioSelect;
