export const KeywordTabs = {
    KEYWORDS: {
        label: 'Keywords',
        key: 'KEYWORDS',
    },
    NEGATIVE_KEYWORDS: {
        label: 'Negative Keywords',
        key: 'NEGATIVE_KEYWORDS',
    },
};
