import {
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_PROXIMITY,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
} from '../../Helpers/helpers';

export const campaignTypeOrder = [
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_PROXIMITY,
];

// Filter Labels
export const FILTER_LABEL_CPU = `CPU`;
export const FILTER_LABEL_REVENUE = `Revenue`;
export const FILTER_LABEL_ROAS = `ROAS`;
export const FILTER_LABEL_ROAS_PERCENT = `ROAS (%)`;
export const FILTER_LABEL_UNITS = `Units`;
export const FILTER_LABEL_IN_STORE = `In Store`;
export const FILTER_LABEL_ONLINE = `Online`;
export const FILTER_LABEL_VIEW_THROUGH_TOTAL = `VIEW-THROUGH TOTAL:`;
export const FILTER_LABEL_CLICK_THROUGH_TOTAL = `CLICK-THROUGH TOTAL:`;

// Column Names

export const COL_LABEL_ADD_TO_CART = `Add to Cart`;
export const COL_LABEL_BRAND = `Brand`;
export const COL_LABEL_CAMPAIGN_NAME = `Campaign Name`;
export const COL_LABEL_CLASS_ID = `Class ID`;
export const COL_LABEL_CLASS_NAME = `Class Name`;
export const COL_LABEL_CLICKS = `Clicks`;
export const COL_LABEL_CLICK_THROUGH_IN_STORE_REVENUE = `Click-through In Store Revenue`;
export const COL_LABEL_CLICK_THROUGH_IN_STORE_ROAS = `Click-through In Store ROAS`;
export const COL_LABEL_CLICK_THROUGH_IN_STORE_ROAS_PERCENT = `Click-through In Store ROAS (%)`;
export const COL_LABEL_CLICK_THROUGH_IN_STORE_UNITS = `Click-through In Store Units`;
export const COL_LABEL_CLICK_THROUGH_ONLINE_REVENUE = `Click-through Online Revenue`;
export const COL_LABEL_CLICK_THROUGH_ONLINE_ROAS = `Click-through Online ROAS`;
export const COL_LABEL_CLICK_THROUGH_ONLINE_ROAS_PERCENT = `Click-through Online ROAS (%)`;
export const COL_LABEL_CLICK_THROUGH_ONLINE_UNITS = `Click-through Online Units`;
export const COL_LABEL_CLICK_THROUGH_TOTAL_CPU = `Click-through Total CPU`;
export const COL_LABEL_CLICK_THROUGH_TOTAL_REVENUE = `Click-through Total Revenue`;
export const COL_LABEL_CLICK_THROUGH_TOTAL_ROAS = `Click-through Total ROAS`;
export const COL_LABEL_CLICK_THROUGH_TOTAL_ROAS_PERCENT = `Click-through Total ROAS (%)`;
export const COL_LABEL_CLICK_THROUGH_TOTAL_UNITS = `Click-through Total Units`;
export const COL_LABEL_CREATIVE_NAME = `Creative Name`;
export const COL_LABEL_CTR = `CTR`;
export const COL_LABEL_DELIVERED_SPEND = `Delivered Spend`;
export const COL_LABEL_DEPARTMENT_ID = `Department ID`;
export const COL_LABEL_DEPARTMENT_NAME = `Department Name`;
export const COL_LABEL_DESCRIPTION = `Description`;
export const COL_LABEL_IMPRESSIONS = `Impressions`;
export const COL_LABEL_PDP_VIEWS = `PDP Views`;
export const COL_LABEL_PLACEMENT_NAME = `Placement Name`;
export const COL_LABEL_REVENUE = `Revenue`;
export const COL_LABEL_ROAS = `ROAS ($)`;
export const COL_LABEL_ROAS_PERCENT = `ROAS (%)`;
export const COL_LABEL_SKU = `SKU`;
export const COL_LABEL_SUB_CLASS_ID = `SubClass ID`;
export const COL_LABEL_SUB_CLASS_NAME = `SubClass Name`;
export const COL_LABEL_UNITS = `Units`;
export const COL_LABEL_VIEW_THROUGH_IN_STORE_REVENUE = `View-through In Store Revenue`;
export const COL_LABEL_VIEW_THROUGH_IN_STORE_ROAS = `View-through In Store ROAS`;
export const COL_LABEL_VIEW_THROUGH_IN_STORE_ROAS_PERCENT = `View-through In Store ROAS (%)`;
export const COL_LABEL_VIEW_THROUGH_IN_STORE_UNITS = `View-through In Store Units`;
export const COL_LABEL_VIEW_THROUGH_ONLINE_REVENUE = `View-through Online Revenue`;
export const COL_LABEL_VIEW_THROUGH_ONLINE_ROAS = `View-through Online ROAS`;
export const COL_LABEL_VIEW_THROUGH_ONLINE_ROAS_PERCENT = `View-through Online ROAS (%)`;
export const COL_LABEL_VIEW_THROUGH_ONLINE_UNITS = `View-through Online Units`;
export const COL_LABEL_VIEW_THROUGH_TOTAL_CPU = `View-through Total CPU`;
export const COL_LABEL_VIEW_THROUGH_TOTAL_REVENUE = `View-through Total Revenue`;
export const COL_LABEL_VIEW_THROUGH_TOTAL_ROAS = `View-through Total ROAS`;
export const COL_LABEL_VIEW_THROUGH_TOTAL_ROAS_PERCENT = `View-through Total ROAS (%)`;
export const COL_LABEL_VIEW_THROUGH_TOTAL_UNITS = `View-through Total Units`;
