import { DataTypes } from 'shared/constants/datatypes';
import * as vehicles from '../../../shared/constants/vehicle';

export const displayColumns = {
    creative: [
        'order_name',
        'creative_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        // Total Revenue
        'revenue_total',
        'online_roas',
        'instore_roas',
        // Total Roas
        'total_roas',
        'online_roas_percent',
        'instore_roas_percent',
        // Total Roas Percent
        'total_roas_percent',
        'units_online',
        'units_instore',
        // Total Units
        'units_total',
        'cpu_total',
        'click_revenue_online',
        'click_revenue_instore',
        // Click-through Total Revenue
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        // Click-through Total Roas
        'click_total_roas',
        'click_online_roas_percent',
        'click_instore_roas_percent',
        // Click-through Total Roas Percent
        'click_total_roas_percent',
        'click_units_online',
        'click_units_instore',
        // Click-through Total Units
        'click_units_total',
        'click_cpu_total',
    ],
    placement: [
        'order_name',
        'placement_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'online_roas',
        'instore_roas',
        'total_roas',
        'online_roas_percent',
        'instore_roas_percent',
        'total_roas_percent',
        'units_online',
        'units_instore',
        'units_total',
        'cpu_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        'click_total_roas',
        'click_online_roas_percent',
        'click_instore_roas_percent',
        'click_total_roas_percent',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'click_cpu_total',
    ],
    product: [
        'sku_id',
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
};

export const defaultColumns = {
    creative: [
        'creative_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'online_roas',
        'instore_roas',
        'total_roas',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        'click_total_roas',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
    placement: [
        'placement_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'online_roas',
        'instore_roas',
        'total_roas',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        'click_total_roas',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
    product: [
        'sku_id',
        'sku_description',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
};

export const modifyColumns = {
    creative: [
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'view_total',
        'view_online',
        'view_instore',
        'cpu_total',
        'click_total',
        'click_online',
        'click_instore',
        'click_cpu_total',
    ],
    placement: [
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'view_total',
        'view_online',
        'view_instore',
        'cpu_total',
        'click_total',
        'click_online',
        'click_instore',
        'click_cpu_total',
    ],
    product: [
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'pdp_page_views',
        'cart_additions',
        'product_view_total',
        'product_view_online',
        'product_view_instore',
        'product_click_total',
        'product_click_online',
        'product_click_instore',
    ],
};

export const columnMap = {
    order_name: {
        label: vehicles.COL_LABEL_CAMPAIGN_NAME,
        type: DataTypes.DESCRIPTION,
        field: 'order_name',
        sortable: true,
        lineBreak: false,
    },
    creative_name: {
        label: vehicles.COL_LABEL_CREATIVE_NAME,
        type: DataTypes.DESCRIPTION,
        field: 'creative_name',
        sortable: true,
        lineBreak: false,
    },
    num_impressions: {
        label: vehicles.COL_LABEL_IMPRESSIONS,
        type: DataTypes.NUMBER,
        field: 'num_impressions',
        sortable: true,
        lineBreak: false,
    },
    ad_spend: {
        label: vehicles.COL_LABEL_DELIVERED_SPEND,
        type: DataTypes.CURRENCY,
        field: 'ad_spend',
        sortable: true,
        lineBreak: false,
    },
    num_clicks: {
        label: vehicles.COL_LABEL_CLICKS,
        type: DataTypes.NUMBER,
        field: 'num_clicks',
        sortable: true,
        lineBreak: false,
    },
    ctr: {
        label: vehicles.COL_LABEL_CTR,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'ctr',
        sortable: true,
        lineBreak: false,
    },
    pdp_page_views: {
        label: vehicles.COL_LABEL_PDP_VIEWS,
        type: DataTypes.DECIMAL,
        field: 'pdp_page_views',
        sortable: true,
        lineBreak: false,
    },
    cart_additions: {
        label: vehicles.COL_LABEL_ADD_TO_CART,
        type: DataTypes.DECIMAL,
        field: 'cart_additions',
        sortable: true,
        lineBreak: false,
    },
    revenue_group: {
        label: vehicles.COL_LABEL_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'revenue',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_revenue_instore',
            click_online: 'click_revenue_online',
            view_through_online: 'revenue_online',
            view_through_instore: 'revenue_instore',
        },
    },
    revenue_instore: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_IN_STORE_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    revenue_online: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_ONLINE_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'revenue_online',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_online: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_ONLINE_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'click_revenue_online',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_instore: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_IN_STORE_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'click_revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    num_units: {
        label: vehicles.COL_LABEL_UNITS,
        type: DataTypes.NUMBER,
        field: 'num_units',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_units_instore',
            click_online: 'click_units_online',
            view_through_online: 'units_online',
            view_through_instore: 'units_instore',
        },
    },
    click_units_instore: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_IN_STORE_UNITS,
        type: DataTypes.DECIMAL,
        field: 'click_units_instore',
        sortable: true,
        lineBreak: false,
    },
    click_units_online: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_ONLINE_UNITS,
        type: DataTypes.DECIMAL,
        field: 'click_units_online',
        sortable: true,
        lineBreak: false,
    },
    units_online: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_ONLINE_UNITS,
        type: DataTypes.DECIMAL,
        field: 'units_online',
        sortable: true,
        lineBreak: false,
    },
    units_instore: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_IN_STORE_UNITS,
        type: DataTypes.DECIMAL,
        field: 'units_instore',
        sortable: true,
        lineBreak: false,
    },
    roas_percent: {
        label: vehicles.COL_LABEL_ROAS_PERCENT,
        type: DataTypes.PERCENT,
        field: 'roas_percent',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_instore_roas_percent',
            click_online: 'click_online_roas_percent',
            view_through_online: 'online_roas_percent',
            view_through_instore: 'instore_roas_percent',
        },
    },
    instore_roas_percent: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_IN_STORE_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'instore_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    online_roas_percent: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_ONLINE_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'online_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_instore_percent: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_IN_STORE_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'click_roas_instore_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_online_percent: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_ONLINE_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'click_roas_online_percent',
        sortable: true,
        lineBreak: false,
    },
    roas_group: {
        label: vehicles.COL_LABEL_ROAS,
        type: DataTypes.CURRENCY,
        field: 'roas',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_instore_roas',
            click_online: 'click_online_roas',
            view_through_online: 'online_roas',
            view_through_instore: 'instore_roas',
        },
    },
    instore_roas: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_IN_STORE_ROAS,
        type: DataTypes.CURRENCY,
        field: 'instore_roas',
        sortable: true,
        lineBreak: false,
    },
    online_roas: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_ONLINE_ROAS,
        type: DataTypes.CURRENCY,
        field: 'online_roas',
        sortable: true,
        lineBreak: false,
    },
    click_instore_roas: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_IN_STORE_ROAS,
        type: DataTypes.CURRENCY,
        field: 'click_instore_roas',
        sortable: true,
        lineBreak: false,
    },
    click_online_roas: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_ONLINE_ROAS,
        type: DataTypes.CURRENCY,
        field: 'click_online_roas',
        sortable: true,
        lineBreak: false,
    },
    placement_name: {
        label: vehicles.COL_LABEL_PLACEMENT_NAME,
        type: DataTypes.DESCRIPTION,
        field: 'placement_name',
        sortable: true,
        lineBreak: false,
    },
    sku_id: {
        label: vehicles.COL_LABEL_SKU,
        type: DataTypes.STRING,
        field: 'sku_id',
        sortable: true,
        lineBreak: false,
    },
    sku_description: {
        label: vehicles.COL_LABEL_DESCRIPTION,
        type: DataTypes.DESCRIPTION,
        field: 'sku_description',
        sortable: true,
        lineBreak: false,
    },
    brand: {
        label: vehicles.COL_LABEL_BRAND,
        type: DataTypes.DESCRIPTION,
        field: 'brand',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_id: {
        label: vehicles.COL_LABEL_DEPARTMENT_ID,
        type: DataTypes.DESCRIPTION,
        field: 'dept_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_name: {
        label: vehicles.COL_LABEL_DEPARTMENT_NAME,
        type: DataTypes.DESCRIPTION,
        field: 'dept_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_id: {
        label: vehicles.COL_LABEL_CLASS_ID,
        type: DataTypes.DESCRIPTION,
        field: 'class_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_name: {
        label: vehicles.COL_LABEL_CLASS_NAME,
        type: DataTypes.DESCRIPTION,
        field: 'class_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_id: {
        label: vehicles.COL_LABEL_SUB_CLASS_ID,
        type: DataTypes.DESCRIPTION,
        field: 'subclass_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_name: {
        label: vehicles.COL_LABEL_SUB_CLASS_NAME,
        type: DataTypes.DESCRIPTION,
        field: 'subclass_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    click_online_roas_percent: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_ONLINE_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'click_online_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_instore_roas_percent: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_IN_STORE_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'click_instore_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    revenue_total: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_TOTAL_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'revenue_total',
        sortable: true,
        lineBreak: false,
    },
    total_roas: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_TOTAL_ROAS,
        type: DataTypes.CURRENCY,
        field: 'total_roas',
        sortable: true,
        lineBreak: false,
    },
    total_roas_percent: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_TOTAL_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'total_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    units_total: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_TOTAL_UNITS,
        type: DataTypes.DECIMAL,
        field: 'units_total',
        sortable: true,
        lineBreak: false,
    },
    cpu_total: {
        label: vehicles.COL_LABEL_VIEW_THROUGH_TOTAL_CPU,
        type: DataTypes.CURRENCY,
        field: 'cpu',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_total: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_TOTAL_REVENUE,
        type: DataTypes.CURRENCY,
        field: 'click_revenue_total',
        sortable: true,
        lineBreak: false,
    },
    click_total_roas: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_TOTAL_ROAS,
        type: DataTypes.CURRENCY,
        field: 'click_total_roas',
        sortable: true,
        lineBreak: false,
    },
    click_total_roas_percent: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_TOTAL_ROAS_PERCENT,
        type: DataTypes.PERCENT_DECIMAL,
        field: 'click_total_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_units_total: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_TOTAL_UNITS,
        type: DataTypes.DECIMAL,
        field: 'click_units_total',
        sortable: true,
        lineBreak: false,
    },
    click_cpu_total: {
        label: vehicles.COL_LABEL_CLICK_THROUGH_TOTAL_CPU,
        type: DataTypes.CURRENCY,
        field: 'click_cpu',
        sortable: true,
        lineBreak: false,
    },
};

export const columnFilterMap = {
    order_name: {
        label: vehicles.COL_LABEL_CAMPAIGN_NAME,
        column: 'order_name',
    },
    creative_name: {
        label: vehicles.COL_LABEL_CREATIVE_NAME,
        column: 'creative_name',
    },
    num_impressions: {
        label: vehicles.COL_LABEL_IMPRESSIONS,
        column: 'num_impressions',
    },
    num_clicks: {
        label: vehicles.COL_LABEL_CLICKS,
        column: 'num_clicks',
    },
    ctr: {
        label: vehicles.COL_LABEL_CTR,
        column: 'ctr',
    },
    ad_spend: {
        label: vehicles.COL_LABEL_DELIVERED_SPEND,
        column: 'ad_spend',
    },
    pdp_page_views: {
        label: vehicles.COL_LABEL_PDP_VIEWS,
        column: 'pdp_page_views',
    },
    cart_additions: {
        label: vehicles.COL_LABEL_ADD_TO_CART,
        column: 'cart_additions',
    },
    view_total: {
        label: vehicles.FILTER_LABEL_VIEW_THROUGH_TOTAL,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'units_total',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'revenue_total',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS,
                column: 'total_roas',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS_PERCENT,
                column: 'total_roas_percent',
            },
        ],
    },
    view_online: {
        label: vehicles.FILTER_LABEL_ONLINE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'units_online',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS_PERCENT,
                column: 'online_roas_percent',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS,
                column: 'online_roas',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'revenue_online',
            },
            {
                label: vehicles.FILTER_LABEL_CPU,
                column: 'cpu_total',
            },
        ],
    },
    view_instore: {
        label: vehicles.FILTER_LABEL_IN_STORE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'units_instore',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS_PERCENT,
                column: 'instore_roas_percent',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS,
                column: 'instore_roas',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'revenue_instore',
            },
        ],
    },
    click_total: {
        label: vehicles.FILTER_LABEL_CLICK_THROUGH_TOTAL,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'click_units_total',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'click_revenue_total',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS,
                column: 'click_total_roas',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS_PERCENT,
                column: 'click_total_roas_percent',
            },
        ],
    },
    click_online: {
        label: vehicles.FILTER_LABEL_ONLINE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'click_units_online',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS_PERCENT,
                column: 'click_online_roas_percent',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS,
                column: 'click_online_roas',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'click_revenue_online',
            },
            {
                label: vehicles.FILTER_LABEL_CPU,
                column: 'click_cpu_total',
            },
        ],
    },
    click_instore: {
        label: vehicles.FILTER_LABEL_IN_STORE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'click_units_instore',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS_PERCENT,
                column: 'click_instore_roas_percent',
            },
            {
                label: vehicles.FILTER_LABEL_ROAS,
                column: 'click_instore_roas',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'click_revenue_instore',
            },
        ],
    },
    sku_description: {
        label: vehicles.COL_LABEL_DESCRIPTION,
        column: 'sku_description',
    },
    brand: {
        label: vehicles.COL_LABEL_BRAND,
        column: 'brand',
    },
    dept_id: {
        label: vehicles.COL_LABEL_DEPARTMENT_ID,
        column: 'dept_id',
    },
    dept_name: {
        label: vehicles.COL_LABEL_DEPARTMENT_NAME,
        column: 'dept_name',
    },
    class_id: {
        label: vehicles.COL_LABEL_CLASS_ID,
        column: 'class_id',
    },
    class_name: {
        label: vehicles.COL_LABEL_CLASS_NAME,
        column: 'class_name',
    },
    subclass_id: {
        label: vehicles.COL_LABEL_SUB_CLASS_ID,
        column: 'subclass_id',
    },
    subclass_name: {
        label: vehicles.COL_LABEL_SUB_CLASS_NAME,
        column: 'subclass_name',
    },
    product_view_total: {
        label: vehicles.FILTER_LABEL_VIEW_THROUGH_TOTAL,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'units_total',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'revenue_total',
            },
        ],
    },
    product_view_online: {
        label: vehicles.FILTER_LABEL_ONLINE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'units_online',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'revenue_online',
            },
        ],
    },
    product_view_instore: {
        label: vehicles.FILTER_LABEL_IN_STORE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'units_instore',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'revenue_instore',
            },
        ],
    },
    product_click_total: {
        label: vehicles.FILTER_LABEL_CLICK_THROUGH_TOTAL,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'click_units_total',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'click_revenue_total',
            },
        ],
    },
    product_click_online: {
        label: vehicles.FILTER_LABEL_ONLINE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'click_units_online',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'click_revenue_online',
            },
        ],
    },
    product_click_instore: {
        label: vehicles.FILTER_LABEL_IN_STORE,
        vertical: true,
        children: [
            {
                label: vehicles.FILTER_LABEL_UNITS,
                column: 'click_units_instore',
            },
            {
                label: vehicles.FILTER_LABEL_REVENUE,
                column: 'click_revenue_instore',
            },
        ],
    },
};
