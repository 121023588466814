import { DataTypes } from 'shared/constants/datatypes';

export const dzSponsoredOptions = [
    // Impressions
    { unit: DataTypes.INTEGER, label: 'Impressions', key: 'impressions' },
    // Clicks
    { unit: DataTypes.INTEGER, label: 'Clicks', key: 'clicks' },
    // Spend
    { unit: DataTypes.CURRENCY, label: 'Delivered Spend', key: 'spend' },
    // Revenue
    { unit: DataTypes.CURRENCY, label: 'Revenue', key: 'revenue' },
];
