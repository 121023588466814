import React from 'react';
import { Grid, InputAdornment, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import Input from '../../../../components/Input';
import { VALIDATE_ON_MODIFIED } from '../../../../utils/constants';
import { BaseAndMaxBidOptions } from '../../componentData/AdGroupSettingsRadioGroupData';
import CampaignManagementRadioGroupMapper from '../../shared/CampaignManagementRadioGroupMapper';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    radio: {
        color: theme.palette.primary.main,
    },
    gridSection: {
        maxWidth: '21rem',
    },
    dollarSign: {
        '& .MuiTypography-colorTextSecondary': {
            color: theme.palette.techBlack,
        },
        marginRight: 0,
    },
}));

const BaseAndMaxBid = () => {
    const classes = useStyles();

    return (
        <Grid item className={classes.gridSection}>
            <Field name="baseBid" component="input">
                {({ input, meta }) => (
                    <Input
                        inputLabel="Base Bid"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        disabled={false}
                        InputProps={{
                            'data-testid': `base-bid-input`,
                            inputMode: 'decimal',
                            maxLength: 255,
                            style: { fontWeight: 600, fontSize: '14px', height: '3rem' },
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    classes={{
                                        root: classes.dollarSign,
                                    }}
                                >
                                    $
                                </InputAdornment>
                            ),
                        }}
                        onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        value={input.value}
                        validateOn={VALIDATE_ON_MODIFIED}
                        input={input}
                        meta={meta}
                    />
                )}
            </Field>
            <InputLabel className={classes.inputLabel}>Max Bid</InputLabel>
            <CampaignManagementRadioGroupMapper options={BaseAndMaxBidOptions} boldRadioLabel={false} />
        </Grid>
    );
};

export default BaseAndMaxBid;
