import React from 'react';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    sectionContainer: {
        maxWidth: '42rem',
    },
    dateFieldContainer: {
        paddingLeft: 0,
    },
    endDateField: {
        paddingLeft: '16px',
        '&.MuiGrid-root': {
            maxWidth: '22rem',
            paddingRight: 0,
        },
    },
    startDateField: {
        '&.MuiGrid-root': {
            maxWidth: '20rem',
            paddingRight: 0,
        },
        '&.MuiInputBase-root': {
            textTransform: 'uppercase',
        },
    },
}));

export function CampaignManagementDatePickerRange({ dates }) {
    const classes = useStyles();

    return (
        <div className={classes.sectionContainer}>
            <Typography
                variant="h5"
                component="h5"
                sx={{
                    fontWeight: 300,
                }}
            >
                Schedule
            </Typography>
            <Grid container className={classes.dateFieldContainer}>
                <Grid item xs={4} className={classes.startDateField}>
                    <InputLabel className={classes.inputLabel}>Start Date</InputLabel>
                    <Field key="startDate" name="startDate" type="input" initialValue={dates?.startDate}>
                        {/* eslint-disable-next-line no-unused-vars */}
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                type="date"
                                fullWidth
                                variant="outlined"
                                format="MM/DD/YYYY"
                                inputProps={{
                                    style: {
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        width: '20rem',
                                        overflow: 'hidden',
                                        textTransform: 'uppercase',
                                    },
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={4} className={classes.endDateField}>
                    <InputLabel className={classes.inputLabel}>End Date (optional)</InputLabel>
                    <Field key="endDate" name="endDate" type="input" initialValue={dates?.endDate}>
                        {/* eslint-disable-next-line no-unused-vars */}
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                type="date"
                                fullWidth
                                variant="outlined"
                                format="MM/DD/YYYY"
                                inputProps={{
                                    style: {
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        width: '20rem',
                                        overflow: 'hidden',
                                        textTransform: 'uppercase',
                                    },
                                }}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        </div>
    );
}

CampaignManagementDatePickerRange.propTypes = {
    dates: PropTypes.object,
};

export default CampaignManagementDatePickerRange;
