/**
 *
 * Navbar
 *
 */

import React, { useContext } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import OutlineButton from 'components/Button/Loadable';

import { AppContext } from 'shared/contexts/app-context';
import { hasPermission } from 'Helpers/helpers';

// import { CAN_USE_SNIPPET_BUILDER, CAN_USE_URL_CREATION_TOOL] } from 'shared/constants/user';
import { Permissions } from 'shared/constants/user/permissions';
import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';

const { CAN_USE_SNIPPET_BUILDER, CAN_USE_URL_CREATION_TOOL } = Permissions;

const useStyles = makeStyles((theme) => ({
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: 'inset 0px -1px 0px #D2D8E2',
        paddingBottom: theme.spacing() / 2,
        '& a': {
            padding: theme.spacing(2),
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            color: '#1D252C',
            textDecoration: 'none',
        },
        '& a:hover': {
            borderBottom: `4px solid ${theme.palette.humanBlue}`,
        },
        '& li': {
            display: 'inline-block',
        },
    },
    navItems: {
        paddingLeft: theme.spacing(3),
    },
    navButtons: {
        paddingRight: theme.spacing(1),
    },
    createButtonText: {
        fontWeight: '500',
        padding: '0 5px',
    },
}));

const activeLinkStyles = {
    color: '#0046be',
    borderBottom: '4px solid currentColor',
    fontWeight: '500',
    fontStyle: 'normal',
};

function Navbar() {
    const classes = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();
    const { authedUser } = useContext(AppContext);
    const featureFlags = useFeatureFlags();

    const pushRoute = (route) => {
        history.push(route);
    };

    const links = [
        { label: 'Dashboard', href: '/dashboard', visible: true },
        { label: 'Campaigns', href: '/campaigns', visible: true },
        { label: 'Recent', href: '/campaigns/recent', visible: true },
        { label: 'My Reports', href: '/campaigns/stacked/watching', visible: true },
    ]
        .filter((linkConfig) => linkConfig && linkConfig.visible)
        .map(({ label, href }) => {
            // Keeps navbar highlighted with multiple subroutes under /campaigns/stacked/:param
            if (label === 'My Reports') {
                return (
                    <li key={href}>
                        <NavLink
                            to={href}
                            activeClassName="selected"
                            activeStyle={activeLinkStyles}
                            isActive={() => pathname.includes('/campaigns/stacked')}
                        >
                            <span>{label}</span>
                        </NavLink>
                    </li>
                );
            }
            if (label === 'Dashboard') {
                return (
                    <li key={href}>
                        <NavLink to={href} exact activeClassName="selected" activeStyle={activeLinkStyles}>
                            <span>{label}</span>
                        </NavLink>
                    </li>
                );
            }

            return (
                <li key={href}>
                    <NavLink to={href} exact activeClassName="selected" activeStyle={activeLinkStyles}>
                        <span>{label}</span>
                    </NavLink>
                </li>
            );
        });

    return (
        <nav className={classes.nav}>
            <ul className={classes.navItems}>{links}</ul>
            {hasPermission(authedUser, [CAN_USE_SNIPPET_BUILDER, CAN_USE_URL_CREATION_TOOL]) && (
                <div className={classes.navButtons}>
                    {featureFlags.showCampaignManagement && (
                        <OutlineButton onClick={() => pushRoute('/create/sponsored')}>
                            <span className={classes.createButtonText}>Create Campaign</span>
                        </OutlineButton>
                    )}
                </div>
            )}
        </nav>
    );
}

Navbar.propTypes = {};

export default Navbar;
