import React from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@material-ui/core';
import ActionMenu from 'components/ActionMenu';

function LightweightTable({ columns, data, actions, selectable, selectedRows, onSelectRow }) {
    const handleSelectRow = (bsin) => {
        const selectedIndex = selectedRows.indexOf(bsin);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, bsin];
        } else {
            newSelectedRows = selectedRows.filter((selectedBsin) => selectedBsin !== bsin);
        }

        onSelectRow(newSelectedRows);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {selectable && <TableCell padding="checkbox" />}
                        {columns?.map((column) => (
                            <TableCell key={column.field + column.label}>
                                <div className={column.headerClassName}>{column.label}</div>
                            </TableCell>
                        ))}
                        {actions && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row) => (
                        <TableRow key={row.bsin}>
                            {selectable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedRows.includes(row.bsin)}
                                        onChange={() => handleSelectRow(row.bsin)}
                                        color="primary"
                                    />
                                </TableCell>
                            )}
                            {columns?.map((column) => (
                                <TableCell key={column.field + column.label}>
                                    <div className={column.cellClassName}>
                                        {column.applyFormatting(row[column.field])}
                                    </div>
                                </TableCell>
                            ))}
                            {actions && (
                                <TableCell>
                                    <ActionMenu actions={actions} item={row} />
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

LightweightTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.func,
    selectable: PropTypes.bool,
    selectedRows: PropTypes.arrayOf(PropTypes.string),
    onSelectRow: PropTypes.func,
};

LightweightTable.defaultProps = {
    selectable: false,
    selectedRows: [],
};

export default LightweightTable;
