/**
 *
 * CampaignReviewForm
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ReviewBudget } from './ReviewBudget';
import { ReviewName } from './ReviewName';
import { ReviewSchedule } from './ReviewSchedule';
import { ReviewOptimizationBidStrategy } from './ReviewOptimizationBidStrategy';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    publishButtonContainer: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    saveDraftButtonContainer: {
        maxWidth: '11rem',
        padding: '0 1rem',
        marginRight: '1rem',
        height: 42,
        borderRadius: 4,
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.standardWhite,
        borderColor: theme.palette.humanBlue,
        color: theme.palette.humanBlue,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.grey,
            color: theme.palette.humanBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
        border: `1px solid ${theme.palette.humanBlue}`,
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
        padding: '2rem',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '2rem',
        width: '100%',
        maxWidth: '42rem',
    },
    buttonDraft: {
        marginLeft: '1rem',
        width: '100%',
        maxWidth: '12rem',
    },
    buttonPublish: {
        width: '100%',
        maxWidth: '28rem',
    },
}));

export function ReviewPage({ loading, review }) {
    const classes = useStyles();

    useEffect(() => {
        review.load();
    });

    const initialReview = review.get();

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h5" sx={{ fontWeight: 300 }}>
                            Summary Review
                        </Typography>
                        <br />
                        <Grid item className={classes.gridSection}>
                            <ReviewName label="Campaign Name" name={initialReview.names.campaignName} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewBudget budget={initialReview.campaignBudgets.campaignBudget} />
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewSchedule header="Schedule" startDate={initialReview.startDate} endDate="" />
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewName label="Ad Group Name" name={initialReview.names.adGroupName} />
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewBudget budget={initialReview.campaignBudgets.adGroupBudget} />
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewSchedule
                            header="Ad Group Schedule"
                            startDate={initialReview.startDate}
                            endDate={initialReview.endDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReviewOptimizationBidStrategy OptAndBid={initialReview.OptAndBid} />
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Grid className={classes.buttonDraft}>
                            <Button
                                data-testid="submit-campaign-setting-form-button"
                                className={classes.saveDraftButtonContainer}
                                type="button"
                                onClick={() => review.save(initialReview)}
                            >
                                <span>Save Draft</span>
                            </Button>
                        </Grid>
                        <Grid className={classes.buttonPublish}>
                            <Button
                                data-testid="submit-campaign-setting-form-button"
                                className={classes.publishButtonContainer}
                                disabled={false}
                                type="submit"
                            >
                                <span>{loading ? 'Loading...' : 'Publish Campaign'}</span>
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

ReviewPage.propTypes = {
    loading: PropTypes.bool,
    review: PropTypes.object,
};

export default ReviewPage;
