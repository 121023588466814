import { DataTypes } from 'shared/constants/datatypes';

export const paidSearchSkuCards = [
    {
        key: 'impressions',
        title: 'Impressions',
        type: DataTypes.NUMBER,
    }, // number
    {
        key: 'clicks',
        title: 'Clicks',
        type: DataTypes.NUMBER,
    }, // number
    {
        key: 'ctr',
        title: 'CTR',
        type: DataTypes.PERCENT,
    }, // percent
    {
        key: 'spend',
        title: 'Delivered Spend',
        type: DataTypes.CURRENCY,
    }, // currency
    {
        key: 'roas',
        title: 'ROAS ($)',
        type: DataTypes.CURRENCY,
    }, // currency
    {
        key: 'revenue_total',
        title: 'Revenue',
        type: DataTypes.CURRENCY,
    }, // currency
];
