import { RadioNamesAndKeys } from './RadioGroupNamesAndKeys';

export const SelectData = {
    attributionSettingsData: {
        postClickSettingsOptions: [
            { name: 'Lookback Window 7 Days', value: '7' },
            { name: 'Lookback Window 14 Days', value: '14' },
            { name: 'Lookback Window 30 Days', value: '30' },
        ],
        postViewSettingsOptions: [
            { name: 'Lookback Window No Post-view', value: '0' },
            { name: 'Lookback Window 1 Day (Default)', value: '1' },
            { name: 'Lookback Window 7 Days', value: '7' },
            { name: 'Lookback Window 14 Days', value: '14' },
            { name: 'Lookback Window 30 Days', value: '30' },
        ],
        productMatchOptions: [
            { name: 'Same Product (default)', value: 'Same Product (default)' },
            { name: 'Same Product, Same Category', value: 'Same Product, Same Category,' },
            {
                name: 'Same Product, Same Category, Same Brand (Default)',
                value: 'Same Product, Same Category, Same Brand (Default)',
            },
        ],
    },
    optimizationStrategyData: [
        {
            name: 'Clicks',
            value: 'Clicks',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
        {
            name: 'Impressions',
            value: 'Impressions',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
        {
            name: 'CTR',
            value: 'CTR',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
        {
            name: 'Delivered Spend',
            value: 'Delivered Spend',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
    ],
};
