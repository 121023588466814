import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    textFieldMui: {
        '&.MuiFormControl-root': {
            maxWidth: '16.5rem',
            width: '100%',
            margin: '.5rem 0',
        },
        overflow: 'hidden',
    },
    dollarSign: {
        '& .MuiTypography-colorTextSecondary': {
            color: '#000000',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: 'red',
        },
        marginRight: 0,
    },
    dollarSignDisabled: {
        '& .MuiTypography-colorTextSecondary': {
            color: 'light dark',
        },
        marginRight: 0,
    },
}));

// eslint-disable-next-line no-unused-vars
export function NumberTextField({ name, value, onChange, key, disabled }) {
    const classes = useStyles();

    return (
        <TextField
            key={key || `${name}-component-key`}
            name={name}
            value={value}
            onChange={onChange}
            id={`radio-label-textField-${name}`}
            className={classes.textFieldMui}
            disabled={disabled}
            variant="outlined"
            onKeyPress={(event) => {
                if (!/[0-9,]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
            InputProps={{
                inputMode: 'numeric',
                startAdornment: (
                    <InputAdornment
                        position="start"
                        classes={{
                            root: disabled ? classes.dollarSignDisabled : classes.dollarSign,
                        }}
                    >
                        $
                    </InputAdornment>
                ),
            }}
        />
    );
}

NumberTextField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    key: PropTypes.string,
    disabled: PropTypes.bool,
};
