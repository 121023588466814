import React from 'react';
import LightweightTable from 'components/LightweightTable';
import { textColumn } from 'components/LightweightTable/columns';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    headerName: {
        textAlign: 'center',
    },
    cellName: {
        textAlign: 'center',
    },
}));

const NegativeKeywords = ({ data }) => {
    const classes = useStyles();

    const columns = [
        textColumn('negativeKeywords', 'Negative Keywords', {
            headerClassName: classes.headerName,
            cellClassName: classes.cellName,
        }),
        textColumn('matchType', 'Match Type', { headerClassName: classes.headerName, cellClassName: classes.cellName }),
    ];

    return <LightweightTable data={data} columns={columns} />;
};

NegativeKeywords.propTypes = {
    data: PropTypes.array.isRequired,
};

export default NegativeKeywords;
