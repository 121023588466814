import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NumberTextField } from './CampaignManagementNumberTextField';

export const TextFieldWrapper = memo((props) => {
    const {
        input: { name, value, onChange },
        key,
        disabled,
    } = props;

    return (
        <>
            <NumberTextField name={name} value={value} key={key} onChange={onChange} disabled={disabled} />
        </>
    );
});

TextFieldWrapper.propTypes = {
    input: PropTypes.object,
    key: PropTypes.string,
    disabled: PropTypes.bool,
};
