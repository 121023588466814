import React from 'react';
import PropTypes from 'prop-types';

const CompleteSvg = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M 0,12 a 12, 12 0 1,1 24, 0 12, 12 0 1,1 -24, 0" className="completeCirclePath1" />
        <path
            d="M10.7949 16.1159C10.7137 16.1982 10.6072 16.2393 10.5008 16.2393C10.3943 16.2393 10.288 16.1982 10.2068 16.1159C10.0443 15.9516 7.23219 13.0781 7.23219 13.0781C7.06974 12.9134 7.06974 12.6466 7.23219 12.482C7.39465 12.3174 7.6579 12.3174 7.82036 12.482L10.5125 15.2103L17.0674 8.56793C17.2291 8.4033 17.4935 8.4033 17.6552 8.56793C17.8176 8.73256 17.8176 8.99934 17.6552 9.16397L10.7949 16.1159Z"
            className="completeCirclePath2"
        />
    </svg>
);

CompleteSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CompleteSvg;
