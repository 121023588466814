import { Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    radioWithSubTitle: {
        padding: '0 9px 90px 9px',
        '&.MuiButtonBase-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    radioWithoutSubTitle: {
        padding: '9px',
        '&.MuiButtonBase-root:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

export const RadioWrapper = memo((props) => {
    const {
        input: { checked, value, name, onChange },
        textFieldWithSubTitle,
    } = props;

    const classes = useStyles();

    return (
        <Radio
            name={name}
            onChange={onChange}
            checked={checked}
            value={value}
            color="primary"
            className={textFieldWithSubTitle ? classes.radioWithSubTitle : classes.radioWithoutSubTitle}
            id="radio-label-subLabel-textField"
        />
    );
});

RadioWrapper.propTypes = {
    input: PropTypes.object,
    textFieldWithSubTitle: PropTypes.bool,
};
