export const TextFieldData = {
    budget: {
        csTextFieldKey: 'csBudgetTextFieldKey',
        agTextFieldKey: 'agBudgetTextFieldKey',
        textFieldName: 'budgetTextField',
    },
    monthly: {
        csBudgetTextFieldKey: 'csMonthlyBudgetTextFieldKey',
        agBudgetTextFieldKey: 'agMonthlyBudgetTextFieldKey',
        textFieldName: 'monthlyTextField',
    },
    daily: {
        csTextFieldKey: 'csDailyBudgetTextFieldKey',
        agTextFieldKey: 'agDailyBudgetTextFieldKey',
        textFieldName: 'dailyTextField',
    },
    optBidding: {
        baseAndMaxBidTextFieldKey: 'baseAndMaxBidTextFieldKey',
    },
};
