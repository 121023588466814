import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import AddButton from 'components/AddButton';

const useStyles = makeStyles((theme) => ({
    topFlexContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        border: `solid 1px ${theme.palette.battleGray}`,
        position: 'relative',
        backgroundColor: 'white',
        padding: '3rem',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
}));

const EmptyState = ({ content, handleOpen }) => {
    const classes = useStyles();

    return (
        <div className={classes.topFlexContainer}>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h2">{content.heading}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{content.subtitle}</Typography>
                </Grid>
            </Grid>
            <div>
                <AddButton label="Products" onClick={handleOpen} />
            </div>
        </div>
    );
};

EmptyState.propTypes = {
    content: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
    }).isRequired,
    handleOpen: PropTypes.func.isRequired,
};

export default EmptyState;
