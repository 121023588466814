import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SidebarTab, SidebarTabView } from 'components/TabSidebar';
import { useParams } from 'react-router-dom';
import CreateProducts from './CreateProducts';
import CampaignReviewForm from '../CampaignManagement/Review/ReviewPage';
import AdGroupForm from '../CampaignManagement/AdGroup/AdGroupForm';
import { CampaignSettingsForm } from '../CampaignManagement/CampaignSettings/CampaignSettingsForm';
import { useSponsoredCreation } from '../../shared/hooks/useSponsoredCreation';
import KeywordsContainer from '../CampaignManagement/Keywords/KeywordsContainer';

export const StepParam = {
    CAMPAIGN_SETTINGS: 'campaign-settings',
    AD_GROUP: 'ad-group',
    PRODUCTS: 'products',
    KEYWORDS: 'keywords',
    REVIEW: 'review',
};

const getTabValue = (tabParam) => {
    const tab = Object.values(StepParam).find((val) => val === tabParam?.toLowerCase());

    return tab || StepParam.CAMPAIGN_SETTINGS;
};

const useStyles = makeStyles(() => ({
    root: {
        padding: '3rem 0 3rem 0',
    },
    content: {
        width: '100%',
        minHeight: '600px',
        position: 'relative',
        top: '-3rem',
        backgroundColor: 'white',
    },
}));

function CreateSponsoredCampaign() {
    const { tab: tabParam } = useParams();

    const classes = useStyles();

    const tabValue = getTabValue(tabParam);

    const { campaignSettings, adGroup, products, keywords, negativeKeywords, review } = useSponsoredCreation();

    // TODO: Add save and onTabChange
    // const onTabChange = () => {
    //     switch (tabValue) {
    //         case StepParam.CAMPAIGN_SETTINGS:
    //             campaignSettings.save();
    //             break;
    //         case StepParam.AD_GROUP:
    //             adGroupSettings.save();
    //             break;
    //         case StepParam.REVIEW:
    //             reviewSettings.save();
    //             break;
    //         default:
    //             break;
    //     }
    // };

    return (
        <div className={classes.root}>
            <SidebarTabView startingValue={tabValue}>
                <SidebarTab
                    type="submit"
                    label="Campaign Settings"
                    value={StepParam.CAMPAIGN_SETTINGS}
                    itemStatus="COMPLETE"
                    pathname={`/create/sponsored/${StepParam.CAMPAIGN_SETTINGS}`}
                >
                    <CampaignSettingsForm campaignSettings={campaignSettings} />
                </SidebarTab>
                <SidebarTab
                    type="submit"
                    label="Ad Group"
                    value={StepParam.AD_GROUP}
                    itemStatus="ERROR"
                    pathname={`/create/sponsored/${StepParam.AD_GROUP}`}
                >
                    <AdGroupForm adGroup={adGroup} />
                </SidebarTab>
                <SidebarTab
                    label="Products"
                    value={StepParam.PRODUCTS}
                    itemStatus="NUMBER"
                    itemNumber={3}
                    pathname={`/create/sponsored/${StepParam.PRODUCTS}`}
                >
                    <div className={classes.content}>
                        <CreateProducts products={products} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Keywords"
                    value={StepParam.KEYWORDS}
                    itemStatus="NUMBER"
                    itemNumber={4}
                    pathname={`/create/sponsored/${StepParam.KEYWORDS}`}
                >
                    <div className={classes.content}>
                        <KeywordsContainer tabs={{ keywords, negativeKeywords }} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Review"
                    value={StepParam.REVIEW}
                    itemStatus="NUMBER"
                    itemNumber={5}
                    pathname={`/create/sponsored/${StepParam.REVIEW}`}
                >
                    <div className={classes.content}>
                        <CampaignReviewForm review={review} />
                    </div>
                </SidebarTab>
            </SidebarTabView>
        </div>
    );
}

export default CreateSponsoredCampaign;
