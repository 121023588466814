import { defaultFormState } from './FormData';

export const campaignDefaults = {
    campaignSettings: {
        ...defaultFormState,
        pageNameField: 'FY25 Holiday Test Campaign',
        startDate: '2022-04-17',
        endDate: '2022-04-17',
        campaignType: 'Sponsored Products',
        campaignBalance: 20000,
        postClickSettings: '',
        productMatchPostClickRow: '',
        postViewSettings: '',
        postViewProductMatch: '',
    },
    adGroup: {
        ...defaultFormState,
        pageNameField: 'FY25 Holiday Beats Studio Head Phones',
        baseBid: 1.75,
        startDate: '2025-01-04',
        endDate: 'YYYY-MM-DD',
        maxBidRadioGroup: '',
        optStrategy: '',
    },
    keywords: [
        { keywords: 'Keyword 1', baseBid: '0.50', keywordBid: '0.50' },
        { keywords: 'Keyword 2', baseBid: '0.50', keywordBid: '0.50' },
        { keywords: 'Keyword 3', baseBid: '0.50', keywordBid: '0.50' },
    ],
    negativeKeywords: [
        { negativeKeywords: 'Negative Keyword 1', matchType: 'Exact' },
        { negativeKeywords: 'Negative Keyword 2', matchType: 'Phrase' },
        { negativeKeywords: 'Negative Keyword 3', matchType: 'Broad' },
    ],
    products: [
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            bsin: '12345',
            color: 'Black',
            upc: '123456789012',
            class: 'Electronics',
            category: 'Headphones',
            status: 'In Stock',
            bid: 1.25,
            price: 100,
        },
    ],
    searchProducts: [
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Matte Black',
            bsin: '6498068',
            color: 'Black',
            upc: '885569449553',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - White',
            bsin: '6498069',
            color: 'White',
            upc: '885569449554',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Red',
            bsin: '6498070',
            color: 'Red',
            upc: '885569449555',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Blue',
            bsin: '6498071',
            color: 'Blue',
            upc: '885569449556',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Green',
            bsin: '6498072',
            color: 'Green',
            upc: '885569449557',
            price: 169.99,
            status: 'In Stock',
        },
    ],
    review: {
        campaignType: 'Sponsored Products',
        names: {
            campaignName: 'FY25 Holiday Test Campaign name',
            adGroupName: 'FY25 Holiday Ad group name',
        },
        campaignBalance: 20000,
        startDate: '2022-04-17',
        endDate: '2023-05-18',
        campaignBudgets: {
            campaignBudget: [
                {
                    label: 'Campaign Capped Budget',
                    budgetAmount: 5000,
                },
                {
                    label: 'Remaining Budget',
                    budgetAmount: 15000,
                },
                {
                    label: 'Available Budget',
                    budgetAmount: 20000,
                },
            ],
            adGroupBudget: [
                {
                    label: 'Ad Group Budget',
                    budgetAmount: 2500,
                },
                {
                    label: 'Monthly Budget',
                    budgetAmount: 500,
                },
                {
                    label: 'Daily Budget',
                    budgetAmount: 150,
                },
            ],
        },
        OptAndBid: {
            optStrategy: 'Clicks',
            cpcBid: [2.5, 5.5],
            productsSelected: 5,
            keywords: 8,
        },
    },
};
