import { DataTypes } from 'shared/constants/datatypes';

export const onsiteVideoDataVizOptions = [
    { unit: DataTypes.INTEGER, label: 'Impressions', key: 'impressions' },
    { unit: DataTypes.PERCENTAGE, label: 'CTR', key: 'ctr' },
    { unit: DataTypes.CURRENCY, label: 'Delivered Spend', key: 'ad_spend' },
    { unit: DataTypes.CURRENCY, label: 'Revenue', key: 'revenue_total' },
    { unit: DataTypes.CURRENCY, label: 'ROAS ($)', key: 'roas' },
    { unit: DataTypes.PERCENTAGE, label: 'VCR', key: 'video_completion_rate_total' },
];
