import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Field } from 'react-final-form';
import { RadioWrapper } from './RadioWrapper';
import { TextFieldWrapper } from './TextFieldWrapper';
import { TextFieldWithSubTitleWrapper } from './TextFieldWithSubTitleWrapper';

const useStyles = makeStyles((theme) => ({
    subTitle: {
        height: 14,
        paddingBottom: '5px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    optionTop: {
        marginTop: theme.spacing(2),
        '&.MuiTypography-root': {
            fontWeight: 500,
        },
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    optionsBelowRadial: {
        marginLeft: '33px',
        paddingTop: 0,
    },
    optionNoTopPadding: {
        paddingTop: 0,
    },
    optionLabels: {
        '&.MuiTypography-root': {
            fontWeight: 600,
            paddingBottom: 0,
        },
    },
    box: {
        display: 'flex',
        height: '72px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '42rem',
        flex: '1 0 40%',
    },
    radio: {
        marginLeft: '-11px',
    },
    radioWithSubTitle: {
        padding: '9px 9px 4px 9px',
    },
}));

export function CampaignManagementRadioOption({ radioOption, boldRadioLabel, index }) {
    const classes = useStyles();

    const radioOptionMap = (option) => {
        if (option.label && option.subLabel && option.textField) {
            return (
                <Grid id="radio-label-subLabel-textField">
                    <Grid item className={classes.optionTop}>
                        <FormControlLabel
                            value={option.namesAndValues.radio.value}
                            control={
                                <Field
                                    key={`${
                                        option.key +
                                        option.namesAndValues.radio.name +
                                        option.namesAndValues.radio.value
                                    }-radio`}
                                    name={option.namesAndValues.radio.name}
                                    value={option.namesAndValues.radio.value}
                                    type="radio"
                                >
                                    {({ input }) => (
                                        <>
                                            <RadioWrapper textFieldWithSubTitle input={input} />
                                            <div className={classes.flexColumn}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.optionLabels}
                                                    sx={{
                                                        marginBottom: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                >
                                                    {option.label}
                                                </Typography>
                                                <Grid item className={classes.optionNoTopPadding}>
                                                    <Typography variant="body1" className={classes.subTitle}>
                                                        {option.subLabel}
                                                    </Typography>
                                                </Grid>
                                                <Field
                                                    key={`${
                                                        option.key +
                                                        option.namesAndValues.textField.name +
                                                        option.namesAndValues.textField.value
                                                    }-textField`}
                                                    name={option.namesAndValues.textField.name}
                                                    initialValue={option.namesAndValues.textField.value}
                                                    disabled={!input.checked}
                                                    component={TextFieldWithSubTitleWrapper}
                                                    type="input"
                                                />
                                            </div>
                                        </>
                                    )}
                                </Field>
                            }
                        />
                    </Grid>
                </Grid>
            );
        }
        if (option.label && option.subLabel && !option.textField) {
            return (
                <Grid id="radio-label-subLabel">
                    <Grid item>
                        <FormControlLabel
                            value={option.value}
                            control={
                                <Field
                                    key={`${option.key + option.name + option.value}-radio`}
                                    name={option.name}
                                    option={option}
                                    value={option.value}
                                    type="radio"
                                    component={RadioWrapper}
                                />
                            }
                            label={
                                <Typography
                                    variant="body1"
                                    className={classes.optionLabels}
                                    sx={{
                                        marginBottom: 0,
                                    }}
                                >
                                    {option.label}
                                </Typography>
                            }
                            labelPlacement="end"
                            id="label-radio-button"
                        />
                    </Grid>
                    <Grid item className={classes.optionsBelowRadial}>
                        <Typography variant="body1" className={classes.subTitle}>
                            {option.subLabel}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (!option.label && !option.subLabel && option.textField) {
            return (
                <Grid id="radio-label-subLabel-textField" className={classes.box}>
                    <FormControlLabel
                        value={option.namesAndValues.radio.value}
                        control={
                            <Field
                                key={`${
                                    option.key + option.namesAndValues.radio.name + option.namesAndValues.radio.value
                                }-radio`}
                                name={option.namesAndValues.radio.name}
                                value={option.namesAndValues.radio.value}
                                type="radio"
                            >
                                {({ input }) => (
                                    <>
                                        <RadioWrapper input={input} />
                                        <Field
                                            key={`${
                                                option.key +
                                                option.namesAndValues.textField.name +
                                                option.namesAndValues.textField.value
                                            }-textField`}
                                            name={option.namesAndValues.textField.name}
                                            type="input"
                                            disabled={!input.checked}
                                            component={TextFieldWrapper}
                                        />
                                    </>
                                )}
                            </Field>
                        }
                        label={
                            <Typography
                                variant="body1"
                                className={classes.optionLabels}
                                sx={{
                                    marginBottom: 0,
                                }}
                            >
                                {option.label}
                            </Typography>
                        }
                        labelPlacement="end"
                        id="label-radio-button"
                    />
                </Grid>
            );
        }
        return (
            <Grid item id="radio-label">
                <FormControlLabel
                    value={option.value}
                    control={
                        <Field
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={option.key + option.name + index}
                            name={option.name}
                            type="radio"
                            value={option.value}
                            component={RadioWrapper}
                        />
                    }
                    label={
                        <Typography variant="body1" className={boldRadioLabel ? classes.optionLabels : ''}>
                            {option.label}
                        </Typography>
                    }
                    labelPlacement="end"
                    id="label-radio-button"
                />
            </Grid>
        );
    };

    return radioOptionMap(radioOption);
}

CampaignManagementRadioOption.propTypes = {
    radioOption: PropTypes.object,
    boldRadioLabel: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    index: PropTypes.number,
};

export default CampaignManagementRadioOption;
