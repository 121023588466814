import React from 'react';
import PropTypes from 'prop-types';

const ErrorSvg = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M 0,12 a 12, 12 0 1,1 24, 0 12, 12 0 1,1 -24, 0" className="errorCirclePath1" />
        <path
            d="M12.444 15.5555C12.0796 15.5555 11.7773 15.2533 11.7773 14.8888V6.88883C11.7773 6.52439 12.0796 6.22217 12.444 6.22217C12.8085 6.22217 13.1107 6.52439 13.1107 6.88883V14.8888C13.1107 15.2533 12.8085 15.5555 12.444 15.5555Z"
            className="errorCirclePath2"
        />
        <path
            d="M12.4436 16.6665C11.9547 16.6665 11.5547 17.0665 11.5547 17.5554C11.5547 18.0443 11.9547 18.4443 12.4436 18.4443C12.9325 18.4443 13.3325 18.0443 13.3325 17.5554C13.3325 17.0665 12.9325 16.6665 12.4436 16.6665Z"
            className="errorCirclePath2"
        />
    </svg>
);

ErrorSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ErrorSvg;
