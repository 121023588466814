import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BestBuyAdsLogoLink from 'components/@shared/BestBuyLogoLink/BestBuyAdsLogoLink';

const contactUsUrl = 'https://www.bestbuyads.com/lets-talk';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        height: '100%',
        backgroundColor: theme.palette.techBlack,
        overflowX: 'scroll',
    },
    header: {
        margin: '0px auto',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 1170,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 20,
        paddingRight: 20,
    },
    headerForTerms: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 1170,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 40,
        paddingRight: 20,
    },

    headerLogo: {
        position: 'relative',
        top: 8,
        display: ' block',
    },
    headerImage: {
        height: 32,
        objectFit: 'contain',
    },
    headerItem: {
        cursor: 'pointer',
        textAlign: 'right',
        '& span': {
            '& a': {
                outline: 'none',
                color: theme.palette.standardWhite,
                textDecoration: 'none',
                fontFamily: theme.typography.fontFamily,
                fontWeight: 700,
                fontSize: 18,
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                textAlign: 'right',
                bottom: 3,
                '&:hover': {
                    color: theme.palette.darkYellow,
                },
            },
        },
        '& span:last-child': {
            paddingLeft: '34px',
        },
    },
}));

const LayoutWithHeader = (props) => {
    const classes = useStyles();

    const isTermsPage = () => props.children.props.location.pathname === '/terms';

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={isTermsPage() ? classes.headerForTerms : classes.header}>
                <Grid item xs={6}>
                    <div className={classes.headerLogo}>
                        <BestBuyAdsLogoLink />
                    </div>
                </Grid>
                {!isTermsPage() ? (
                    <Grid item xs={6} className={classes.headerItem}>
                        <span>
                            <a rel="noopener" target="_blank" href={contactUsUrl}>
                                Contact Us
                            </a>
                        </span>
                    </Grid>
                ) : null}
            </Grid>
            <div>{props.children}</div>
        </Grid>
    );
};

LayoutWithHeader.propTypes = {
    children: PropTypes.element.isRequired,
};

export default LayoutWithHeader;
