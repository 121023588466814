import React from 'react';
import OptimizationStrategyFormControlSelect from './OptimizationStrategyFormControlSelect';
import { SelectData } from '../../componentData/SelectData';

export function OptimizationStrategy() {
    return (
        <OptimizationStrategyFormControlSelect
            label="Optimization Strategy"
            options={SelectData.optimizationStrategyData}
        />
    );
}

OptimizationStrategy.propTypes = {};

export default OptimizationStrategy;
