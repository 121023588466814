export const defaultFormState = {
    pageNameField: '',
    startDate: 'YYYY-MM-DD',
    endDate: 'YYYY-MM-DD',
    budgetRadioGroup: '',
    budgetTextField: '',
    monthlyRadioGroup: '',
    monthlyTextField: '',
    dailyRadioGroup: '',
    dailyTextField: '',
};
