import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NumberTextField } from './CampaignManagementNumberTextField';

const useStyles = makeStyles(() => ({
    optionNoTopPadding: {
        paddingTop: 0,
    },
}));

export const TextFieldWithSubTitleWrapper = memo((props) => {
    const {
        input: { name, value, onChange },
        disabled,
    } = props;

    const classes = useStyles();

    return (
        <>
            <Grid item className={classes.optionNoTopPadding}>
                <NumberTextField name={name} value={value} onChange={onChange} disabled={disabled} />
            </Grid>
        </>
    );
});

TextFieldWithSubTitleWrapper.propTypes = {
    input: PropTypes.object,
    disabled: PropTypes.bool,
};
