import { DataTypes } from 'shared/constants/datatypes';

export const dzSocialOptions = [
    { unit: DataTypes.INTEGER, label: 'Impressions', key: 'impressions' },
    { unit: DataTypes.INTEGER, label: 'Clicks', key: 'clicks' },
    { unit: DataTypes.PERCENTAGE, label: 'CTR', key: 'ctr' },
    { unit: DataTypes.CURRENCY, label: 'Delivered Spend', key: 'spend' },
    { unit: DataTypes.INTEGER, label: 'Engagements', key: 'engagements' },
    { unit: DataTypes.PERCENTAGE, label: 'Engagement Rate', key: 'engagement_rate' },
];
