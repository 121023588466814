import { DataTypes } from 'shared/constants/datatypes';

export const dzProximityOptions = [
    // Unique Reach
    { unit: DataTypes.INTEGER, label: 'Unique Reach', key: 'unique_reach' },
    // Total Taps (Notification Taps plus Banner Taps)
    { unit: DataTypes.INTEGER, label: 'Taps', key: 'total_taps' },
    // Tap Rate (Total Taps divided by Total Unique Reach)
    { unit: DataTypes.PERCENTAGE, label: 'Tap Rate', key: 'tap_rate' },
    // Revenue  (Total In Store Revenue plus Total Online Revenue - "View" Revenue)
    { unit: DataTypes.CURRENCY, label: 'Revenue', key: 'total_revenue' },
];
