import { DataTypes } from 'shared/constants/datatypes';

export const displayCards = [
    {
        key: 'impressions',
        title: 'Impressions',
        type: DataTypes.NUMBER,
    }, // number
    {
        key: 'clicks',
        title: 'Clicks',
        type: DataTypes.NUMBER,
    }, // number
    {
        key: 'ctr',
        title: 'CTR',
        type: DataTypes.PERCENT,
    }, // percent
    {
        key: 'ad_spend',
        title: 'Delivered Spend',
        type: DataTypes.CURRENCY,
    }, // currency
    // TODO: calculated on API or client?
    {
        key: 'roas',
        title: 'ROAS ($)',
        type: DataTypes.CURRENCY,
    }, // currency
    {
        key: 'total_revenue',
        title: 'Revenue',
        type: DataTypes.CURRENCY,
    }, // currency
];
