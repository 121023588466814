import { DataTypes } from 'shared/constants/datatypes';

export const displayOptions = (offsiteType) => {
    const baseDisplayOptions = [
        { unit: DataTypes.INTEGER, label: 'Impressions', key: 'impressions' },
        { unit: DataTypes.PERCENTAGE, label: 'CTR', key: 'ctr' },
        { unit: DataTypes.CURRENCY, label: 'Delivered Spend', key: 'ad_spend' },
        { unit: DataTypes.CURRENCY, label: 'Revenue', key: 'revenue_total' },
        { unit: DataTypes.CURRENCY, label: 'ROAS ($)', key: 'roas_total' },
    ];

    if (offsiteType === 'display') {
        /// added object for offsite display
        baseDisplayOptions.splice(1, 0, { unit: DataTypes.INTEGER, label: 'Clicks', key: 'clicks' });
    } else {
        /// addition for offsite video
        baseDisplayOptions.splice(1, 0, {
            unit: DataTypes.PERCENTAGE,
            label: 'VCR',
            key: 'video_completion_rate_total',
        });
    }

    return baseDisplayOptions;
};
