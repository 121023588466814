import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    selectContainer: {
        display: 'flex',
    },
    leftSelect: {
        maxWidth: '15rem',
        width: '100%',
        marginRight: '1rem',
    },
    rightSelect: {
        maxWidth: '26rem',
        width: '100%',
        marginRight: 0,
    },
}));

export function CampaignSettingsAttributionFormControlSelect({ label, options, id, isLeft, name }) {
    const classes = useStyles();
    const defaultValue = options[1].value;
    return (
        <Field name={name} type="select">
            {/* eslint-disable-next-line no-unused-vars */}
            {({ input, meta }) => (
                <div className={isLeft ? classes.leftSelect : classes.rightSelect}>
                    <InputLabel className={classes.inputLabel}>{label}</InputLabel>
                    <Select
                        {...input}
                        labelId={id}
                        id={id}
                        value={input.value || defaultValue}
                        variant="outlined"
                        autoWidth
                        className={classes.selectContainer}
                        onChange={(e) => input.onChange(e.target.value)}
                    >
                        {options &&
                            options.map((option) => (
                                <MenuItem value={option.value} key={`${option.value}`}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </Select>
                </div>
            )}
        </Field>
    );
}

CampaignSettingsAttributionFormControlSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    id: PropTypes.string,
    isLeft: PropTypes.bool,
    name: PropTypes.string,
};

export default CampaignSettingsAttributionFormControlSelect;
