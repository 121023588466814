import React from 'react';
import LightweightTable from 'components/LightweightTable';
import { currencyColumn, textColumn } from 'components/LightweightTable/columns';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    headerName: {
        textAlign: 'center',
    },
    cellName: {
        textAlign: 'center',
    },
}));

const Keywords = ({ data }) => {
    const classes = useStyles();

    const columns = [
        textColumn('keywords', 'Keywords', { headerClassName: classes.headerName, cellClassName: classes.cellName }),
        currencyColumn('baseBid', 'Base Bid', { headerClassName: classes.headerName, cellClassName: classes.cellName }),
        currencyColumn('keywordBid', 'Keyword Bid', {
            headerClassName: classes.headerName,
            cellClassName: classes.cellName,
        }),
    ];

    const actions = (item) => [
        {
            label: 'Update Bid',
            onClick: () => {
                // eslint-disable-next-line no-console
                console.log('Update Bid', item);
            },
        },
        {
            label: 'Remove Keyword',
            onClick: () => {
                // eslint-disable-next-line no-console
                console.log('Remove Keyword', item);
            },
        },
    ];

    return <LightweightTable data={data} columns={columns} actions={actions} />;
};

Keywords.propTypes = {
    data: PropTypes.array.isRequired,
};

export default Keywords;
