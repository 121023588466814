export const DataTypes = Object.freeze({
    BOOLEAN: `boolean`,
    CURRENCY: `currency`,
    DECIMAL: `decimal`,
    DESCRIPTION: `description`,
    INTEGER: `integer`,
    NUMBER: `number`,
    PERCENT: `percent`,
    PERCENTAGE: `percentage`,
    PERCENT_DECIMAL: `percent_decimal`,
    STRING: `string`,
});
