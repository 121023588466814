import { DataTypes } from 'shared/constants/datatypes';

export const dzDisplayOptions = [
    // Impressions
    { unit: DataTypes.INTEGER, label: 'Impressions', key: 'impressions' },
    // Clicks
    { unit: DataTypes.INTEGER, label: 'Clicks', key: 'clicks' },
    { unit: DataTypes.PERCENTAGE, label: 'CTR', key: 'ctr' },
    // Spend
    { unit: DataTypes.CURRENCY, label: 'Delivered Spend', key: 'ad_spend' },
    // Revenue (sum of Total Online Revenue and Total In-Store Revenue)
    { unit: DataTypes.CURRENCY, label: 'Revenue', key: 'revenue_total' },
    { unit: DataTypes.CURRENCY, label: 'ROAS ($)', key: 'roas' },
];
