import React, { useState } from 'react';
import { Tab, Typography, makeStyles, Divider } from '@material-ui/core';
import MaterialTabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import { KeywordTabs } from './shared/constant';
import Keywords from './tables/Keywords';
import NegativeKeywords from './tables/NegativeKeywords';
import AddButton from '../../../components/AddButton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 0 2rem 0',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    tab: {
        width: '175px',
        height: '14px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        opacity: 1,
        color: theme.palette.lightGray,
        '&:hover': {
            boxShadow: `inset 0 -4px 0 0 ${theme.palette.humanBlue}`,
        },
        '&.Mui-selected': {
            width: '175px',
            height: '30px',
            color: theme.palette.humanBlue,
        },
    },
    indicator: {
        backgroundColor: theme.palette.humanBlue,
        height: '4px',
    },
    drawer: {
        width: '350px',
        padding: '1rem',
    },
}));

const KeywordContainer = ({ tabs }) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);

    const keywords = tabs.keywords.get();
    const negativeKeywords = tabs.negativeKeywords.get();

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    let title = '';
    let tableContent;
    let buttonLabel = '';

    switch (currentTab) {
        case 0:
            title = `Keywords (${keywords.length})`;
            tableContent = <Keywords data={keywords} />;
            buttonLabel = 'Keywords';
            break;
        case 1:
            title = `Negative Keywords (${negativeKeywords.length})`;
            tableContent = <NegativeKeywords data={negativeKeywords} />;
            buttonLabel = 'Neg. Keywords';
            break;
        default:
            title = '';
            tableContent = null;
            buttonLabel = '';
            break;
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography component="h2" align="left" className={classes.headline}>
                    {title}
                </Typography>
                <AddButton label={buttonLabel} onClick={() => {}} />
            </div>
            <MaterialTabs value={currentTab} onChange={handleTabChange} classes={{ indicator: classes.indicator }}>
                {Object.entries(KeywordTabs).map(([key, tab]) => (
                    <Tab key={key} label={tab.label} className={classes.tab} />
                ))}
            </MaterialTabs>
            <Divider />
            {tableContent}
        </div>
    );
};

KeywordContainer.propTypes = {
    tabs: PropTypes.shape({
        keywords: PropTypes.object.isRequired,
        negativeKeywords: PropTypes.object.isRequired,
    }).isRequired,
};

export default KeywordContainer;
