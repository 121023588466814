import { DataTypes } from 'shared/constants/datatypes';

export const socialColumns = {
    publisher: ['publisher', 'impressions', 'clicks', 'ctr', 'spend', 'engagements', 'engagement_rate'],
    post: ['post_type', 'impressions', 'clicks', 'ctr', 'spend', 'engagements', 'engagement_rate'],
    audience: ['audience_name', 'impressions', 'clicks', 'ctr', 'spend', 'engagements', 'engagement_rate'],
};

// include all but anchor (first in every array)
export const defaultColumns = {
    publisher: socialColumns.publisher,
    post: socialColumns.post,
    audience: socialColumns.audience,
};

export const modifyColumns = {
    publisher: socialColumns.publisher.slice(1),
    post: socialColumns.post.slice(1),
    audience: socialColumns.audience.slice(1),
};

export const columnMap = {
    publisher: {
        label: 'Publisher',
        type: DataTypes.STRING,
        field: 'publisher',
        sortable: true,
        lineBreak: false,
    },
    impressions: {
        label: 'Impressions',
        type: DataTypes.NUMBER,
        field: 'impressions',
        sortable: true,
        lineBreak: false,
    },
    clicks: {
        label: 'Clicks',
        type: DataTypes.NUMBER,
        field: 'clicks',
        sortable: true,
        lineBreak: false,
    },
    ctr: {
        label: 'CTR',
        type: DataTypes.PERCENT_DECIMAL,
        field: 'ctr',
        sortable: true,
        lineBreak: false,
    },
    spend: {
        label: 'Delivered Spend',
        type: DataTypes.CURRENCY,
        field: 'spend',
        sortable: true,
        lineBreak: false,
    },
    engagements: {
        label: 'Engagements',
        type: DataTypes.NUMBER,
        field: 'engagements',
        sortable: true,
        lineBreak: false,
    },
    engagement_rate: {
        label: 'Engagement Rate',
        type: DataTypes.PERCENT_DECIMAL,
        field: 'engagement_rate',
        sortable: true,
        lineBreak: false,
    },
    post_type: {
        label: 'Post',
        type: DataTypes.STRING,
        field: 'post_type',
        sortable: true,
        lineBreak: false,
    },
    audience_name: {
        label: 'Audience',
        type: DataTypes.STRING,
        field: 'audience_name',
        sortable: true,
        lineBreak: false,
    },
};
