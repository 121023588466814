import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    budgetOptionLabel: {
        paddingTop: 0,
        marginRight: '2rem',
    },
    budgetAmount: {
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: '1rem',
    },
    subTitle: {
        height: 14,
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    budgetRowContainer: {
        display: 'flex',
        container: 'row',
        justifyContent: 'space-between',
        maxWidth: '34rem',
    },
    budgetColumnContainer: {
        maxWidth: '90px',
        width: '100%',
    },
}));

export function ReviewBudget({ budget }) {
    const classes = useStyles();

    const formatCurrency = (number) =>
        new Intl.NumberFormat('en-US', {
            trailingZeroDisplay: 'stripIfInteger',
            style: 'currency',
            currency: 'USD',
        }).format(number);

    return (
        <div direction="row" spacing="3" className={classes.budgetRowContainer}>
            {budget.map((budgetItem) => (
                <div key={budgetItem.label} className={classes.budgetColumnContainer}>
                    <Grid item className={classes.budgetOptionLabel}>
                        <Typography variant="body1" className={classes.subTitle}>
                            {budgetItem.label}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.budgetAmount}>
                        <Typography variant="h5" component="h5" sx={{ fontWeight: 300 }}>
                            {formatCurrency(budgetItem.budgetAmount)}
                        </Typography>
                    </Grid>
                </div>
            ))}
        </div>
    );
}

ReviewBudget.propTypes = {
    budget: PropTypes.array,
};

export default ReviewBudget;
