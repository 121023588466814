import { DataTypes } from 'shared/constants/datatypes';

export const socialCards = [
    {
        key: 'impressions',
        title: 'Impressions',
        type: DataTypes.NUMBER,
    },
    {
        key: 'clicks',
        title: 'Clicks',
        type: DataTypes.NUMBER,
    },
    {
        key: 'ctr',
        title: 'CTR',
        type: DataTypes.PERCENT_DECIMAL,
    },
    {
        key: 'spend',
        title: 'Delivered Spend',
        type: DataTypes.CURRENCY,
    },
    {
        key: 'engagements',
        title: 'Engagements',
        type: DataTypes.NUMBER,
    },
    {
        key: 'engagement_rate',
        title: 'Engagement Rate',
        type: DataTypes.PERCENT_DECIMAL,
    },
];
